import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import model4 from "../../assets/images/Malaki_Portfolio5.webp";
import model5 from "../../assets/images/Malaki_Portfolio7.webp";
import model6 from "../../assets/images/Malaki_Portfolio6.webp";
import model4JPG from "../../assets/images/Malaki_Portfolio5.jpg";
import model5JPG from "../../assets/images/Malaki_Portfolio7.jpg";
import model6JPG from "../../assets/images/Malaki_Portfolio6.jpg";
import "./Images.scss";
import gsap, { Power1 } from "gsap";
import { Power2 } from "gsap";

function SecondImages(props) {
  const firsty = 1000;
  const secondY = 3000;
  const thirstY = 6000;
  const firstDur = 1;
  const secondDur = 1;
  const thirstDur = 1;
  // useEffect(() => {
  //   gsap.set(".about-model-four", { y: firsty });
  //   gsap.set(".about-model-five", { y: secondY });
  //   gsap.set(".about-model-six", { y: thirstY });
  //   gsap.set(".home-title-Caftans", { opacity: 0 });
  //   gsap.set(".home-title-jewels", { opacity: 0 });
  //   gsap.set(".home-title-bridal", { opacity: 0 });
  //   gsap.set(".home-title-and", { opacity: 0 });
  //   gsap.set(".home-title-products", { opacity: 0 });
  //   let tl3 = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: ".contact", // Trigger is on about-model-col
  //       onLeave: (self) => {
  //         gsap.fromTo(
  //           ".about-model-four",
  //           { y: 0 },
  //           {
  //             y: -1 * firsty,
  //             ease: Power2.easeOut,
  //             duration: firstDur,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-five",
  //           { y: 0 },
  //           {
  //             y: -1 * secondY,
  //             ease: Power2.easeOut,
  //             duration: secondDur,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-six",
  //           { y: 0 },
  //           {
  //             y: -1 * thirstY,
  //             ease: Power2.easeOut,
  //             duration: thirstDur,
  //           }
  //         );
  //       },
  //       onEnterBack: (self) => {},
  //     },
  //   });

  //   let tl4 = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: "#trigger-second", // Trigger is on about-model-col
  //       start: "top top",
  //       onEnter: (self) => {
  //         console.log("onEnter #trigger-second");
  //         gsap.fromTo(
  //           ".about-model-four",
  //           {
  //             y: firsty,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: firstDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-five",
  //           {
  //             y: secondY,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: secondDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-six",
  //           {
  //             y: thirstY,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: thirstDur }
  //         );
  //       },
  //       onLeave: (self) => {
  //         console.log("onLeave #trigger-second");
  //         // gsap.fromTo(
  //         //   ".about-model-four",
  //         //   { y: 0 },
  //         //   {
  //         //     y: -1 * firsty,
  //         //     ease: Power2.easeOut,
  //         //     duration: firstDur,
  //         //   }
  //         // );
  //         // gsap.fromTo(
  //         //   ".about-model-five",
  //         //   { y: 0 },
  //         //   {
  //         //     y: -1 * secondY,
  //         //     ease: Power2.easeOut,
  //         //     duration: secondDur,
  //         //   }
  //         // );
  //         // gsap.fromTo(
  //         //   ".about-model-six",
  //         //   { y: 0 },
  //         //   {
  //         //     y: -1 * thirstY,
  //         //     ease: Power2.easeOut,
  //         //     duration: thirstDur,
  //         //   }
  //         // );
  //       },
  //       onLeaveBack: (self) => {
  //         console.log("onLeaveBack #trigger-second");
  //         gsap.fromTo(
  //           ".about-model-four",

  //           { y: 0 },
  //           {
  //             y: firsty / 2,
  //             ease: Power2.easeOut,

  //             duration: firstDur,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-five",

  //           { y: 0 },
  //           {
  //             y: secondY / 2,
  //             ease: Power2.easeOut,

  //             duration: secondDur,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-six",

  //           { y: 0 },
  //           {
  //             y: thirstY / 2,
  //             ease: Power2.easeOut,

  //             duration: thirstDur,
  //           }
  //         );
  //       },

  //       onEnterBack: (self) => {
  //         console.log("onEnterBack #trigger-second");
  //         gsap.fromTo(
  //           ".about-model-four",
  //           {
  //             y: -1 * firsty,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: firstDur, opacity: 1 }
  //         );
  //         gsap.fromTo(
  //           ".about-model-five",
  //           {
  //             y: -1 * secondY,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: secondDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-six",
  //           {
  //             y: -1 * thirstY,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: thirstDur }
  //         );
  //       },
  //     },
  //   });
  //   let tl5 = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: "#trigger-third", // Trigger is on about-model-col
  //       onEnter: (self) => {
  //         console.log("onEnter #trigger-third");
  //         gsap.fromTo(
  //           ".about-model-four",
  //           { y: 0 },
  //           {
  //             y: (-1 * firsty) / 4,
  //             ease: Power2.easeOut,
  //             duration: firstDur,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-five",
  //           { y: 0 },
  //           {
  //             y: (-1 * secondY) / 4,
  //             ease: Power2.easeOut,
  //             duration: secondDur,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-six",
  //           { y: 0 },
  //           {
  //             y: (-1 * thirstY) / 4,
  //             ease: Power2.easeOut,
  //             duration: thirstDur,
  //           }
  //         );
  //       },
  //     },
  //   });
  //   let tl6 = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: "#home-wrapper", // Trigger is on about-model-col
  //       onEnter: (self) => {
  //         console.log("onEnter #trigger-third");
  //         gsap.fromTo(
  //           ".home-title-Caftans, .home-title-jewels, .home-title-and",
  //           {
  //             opacity: 0,
  //           },
  //           {
  //             ease: Power2.easeOut,
  //             delay: firstDur / 8,
  //             duration: 2,
  //             opacity: 1,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".home-title-bridal",
  //           {
  //             opacity: 0,
  //           },
  //           {
  //             ease: Power2.easeOut,
  //             delay: firstDur / 3,
  //             duration: 2,
  //             opacity: 1,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".home-title-products",
  //           {
  //             opacity: 0,
  //           },
  //           {
  //             ease: Power2.easeOut,
  //             delay: firstDur / 2,
  //             duration: 2,
  //             opacity: 1,
  //           }
  //         );
  //       },
  //       onEnterBack: (self) => {},
  //     },
  //   });
  // }, []);
  return (
    <Row
      id={props.id}
      className="images-row images-row-2"
      style={{ marginBottom: "10.977vw" }}
    >
      <div id="trigger-second"></div>
      <Col
        className="images-col-1"
        lg={4}
        style={{ left: "13.021vw", top: "45.573vw" }}
        id="secondimages"
      >
        <picture>
          <source srcSet={model4} type="image/webp" />
          <img src={model4JPG} alt="" className="about-model-four" />
        </picture>
      </Col>
      <Col lg={5}>
        <picture>
          <source srcSet={model5} type="image/webp" />
          <img src={model5JPG} alt="" className="about-model-five" />
        </picture>
      </Col>
      <Col lg={3} style={{ left: "-6.51vw", top: "9.766vw" }}>
        <picture>
          <source srcSet={model6} type="image/webp" />
          <img src={model6JPG} alt="" className="about-model-six" />
        </picture>
      </Col>
    </Row>
  );
}
export default SecondImages;
