import React from "react";
import model from "../../assets/images/Malaki_Portfolio_about.webp";
import model2 from "../../assets/images/Malaki_Portfolio3.webp";
import model3 from "../../assets/images/Malaki_Portfolio4.webp";
import model4 from "../../assets/images/Malaki_Portfolio5.webp";
import model5 from "../../assets/images/Malaki_Portfolio7.webp";
import model6 from "../../assets/images/Malaki_Portfolio6.webp";
import modelJPG from "../../assets/images/Malaki_Portfolio_about.jpg";
import model2JPG from "../../assets/images/Malaki_Portfolio3.jpg";
import model3JPG from "../../assets/images/Malaki_Portfolio4.jpg";
import model4JPG from "../../assets/images/Malaki_Portfolio5.jpg";
import model5JPG from "../../assets/images/Malaki_Portfolio7.jpg";
import model6JPG from "../../assets/images/Malaki_Portfolio6.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CustomCarousel.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function CustomCarousel() {
  return (
    <div className="custom-carousel">
      <Carousel responsive={responsive} ssr={true} infinite={true}>
        <div>
          <picture>
            <source srcSet={model} type="image/webp" />
            <img src={modelJPG} alt="" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={model2} type="image/webp" />
            <img src={model2JPG} alt="" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={model3} type="image/webp" />
            <img src={model3JPG} alt="" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={model4} type="image/webp" />
            <img src={model4JPG} alt="" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={model5} type="image/webp" />
            <img src={model5JPG} alt="" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={model6} type="image/webp" />
            <img src={model6JPG} alt="" />
          </picture>
        </div>
      </Carousel>
    </div>
  );
}
export default CustomCarousel;
