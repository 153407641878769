import "./App.css";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Images from "./components/images/Images";
import React from "react";
import CustomNav from "./components/customNav/CustomNav";
import Contact from "./components/contact/Contact";
import SecondImages from "./components/images/SecondImages";
import CustomCarousel from "./components/customCarousel/CustomCarousel";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.scrollNext = this.scrollNext.bind(this);
    this.scrollDone = this.scrollDone.bind(this);
    this.isElementInViewport = this.isElementInViewport.bind(this);
    this.state = {
      scrollPos: 0,
      initialId: 0,
      vw: 900,
    };
  }

  async scrollDone() {
    return new Promise((resolve) => {
      let scrollTimeout;
      window.removeEventListener("scroll", this.scrollNext);
      if (scrollTimeout) clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        window.addEventListener("scroll", this.scrollNext);
        this.setState({ scrollPos: window.pageYOffset });
        document.body.style.overflow = "initial";
        resolve("resolved");
      }, 1000);
    });
  }

  isElementInViewport(el) {
    var rect = el.getBoundingClientRect();

    return (
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left <
        (window.innerWidth ||
          document.documentElement.clientWidth) /* or $(window).width() */ &&
      rect.top <
        (window.innerHeight ||
          document.documentElement.clientHeight) /* or $(window).height() */
    );
  }
  scrollNext() {
    if (
      !this.isElementInViewport(document.getElementById("0")) &&
      this.isElementInViewport(document.getElementById("1")) &&
      !this.isElementInViewport(document.getElementById("2"))
    ) {
    } else {
      if (
        document.getElementById(this.state.initialId + 1) &&
        window.pageYOffset > this.state.scrollPos
      ) {
        document.body.style.overflow = "hidden";
        this.setState({ initialId: this.state.initialId + 1 });
        let ele = document.getElementById(this.state.initialId);
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
        this.scrollDone();
      } else if (
        document.getElementById(this.state.initialId - 1) &&
        window.pageYOffset < this.state.scrollPos
      ) {
        document.body.style.overflow = "hidden";
        this.setState({ initialId: this.state.initialId - 1 });
        let ele = document.getElementById(this.state.initialId);
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
        this.scrollDone();
      }
    }
  }

  componentDidMount() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    this.setState({
      scrollPos: document
        .getElementById(this.state.initialId)
        .getBoundingClientRect().top,
    });
    if (vw >= 992) {
      window.addEventListener("hashchange", async () => {
        this.scrollDone();
      });
      window.addEventListener("scroll", this.scrollNext);
    } else if (vw < 768) {
      var prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("navbar").style.top = "0";
        } else {
          document.getElementById("navbar").style.top = "-50px";
        }
        prevScrollpos = currentScrollPos;
      };
    }
  }

  render() {
    return (
      <div>
        <Home id="0" />
        <About id="1" />
        <Images id="2" />
        <SecondImages id="3" />
        <CustomCarousel vw={this.state.vw} />
        <Contact id="4" />
        <CustomNav />
      </div>
    );
  }
}

export default App;
