import "./About.scss";
import logo from "../../assets/images/logo.png";
import model from "../../assets/images/Malaki_Portfolio_about.webp";
import modelJPG from "../../assets/images/Malaki_Portfolio_about.jpg";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, { useEffect } from "react";
import gsap, { Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TweenMax, SlowMo } from "gsap";
import { Power2, Circ } from "gsap";

function About(props) {
  // const firsty = 1000;
  // const secondY = 3000;
  // const thirstY = 6000;
  // const firstDur = 1;
  // const secondDur = 1;
  // const thirstDur = 1;
  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger); // Register scroll
  //   gsap.set(".about-model-first", { y: firsty });
  //   gsap.set(".about-model-second", { y: secondY });
  //   gsap.set(".about-model-third", { y: thirstY });
  //   gsap.set(".about-model", { y: firsty / 2 });
  //   gsap.set(".about-title-1", { opacity: 0 });
  //   gsap.set(".about-title-2", { opacity: 0 });
  //   gsap.set(".about-title-3", { opacity: 0 });

  //   let tl = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: ".about-model-wrapper", // Trigger is on about-model-col
  //       onLeave: (self) => {
  //         gsap.fromTo(
  //           ".about-model-first",
  //           {
  //             y: firsty,
  //             opacity: 0,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: firstDur, opacity: 1 }
  //         );
  //         gsap.fromTo(
  //           ".about-model-second",
  //           {
  //             y: secondY,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: secondDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-third",
  //           {
  //             y: thirstY,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: thirstDur }
  //         );
  //       },

  //       onEnterBack: (self) => {
  //         gsap.fromTo(
  //           ".about-model-first",
  //           {
  //             y: 0,
  //           },
  //           { y: firsty, ease: Power1.easeIn, duration: firstDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-second",
  //           {
  //             y: 0,
  //           },
  //           { y: secondY, ease: Power1.easeIn, duration: secondDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-third",
  //           {
  //             y: 0,
  //           },
  //           { y: thirstY, ease: Power1.easeIn, duration: thirstDur }
  //         );
  //       },
  //       onEnter: (self) => {
  //         gsap.fromTo(
  //           ".about-title-1",
  //           {
  //             opacity: 0,
  //           },
  //           {
  //             ease: Power2.easeOut,
  //             delay: firstDur / 8,
  //             duration: 2,
  //             opacity: 1,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-title-2",
  //           {
  //             opacity: 0,
  //           },
  //           {
  //             ease: Power2.easeOut,
  //             delay: firstDur / 3,
  //             duration: 2,
  //             opacity: 1,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-title-3",
  //           {
  //             opacity: 0,
  //           },
  //           {
  //             ease: Power2.easeOut,
  //             delay: firstDur / 2,
  //             duration: 2,
  //             opacity: 1,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model",
  //           {
  //             y: firsty / 2,
  //             opacity: 0,
  //           },
  //           { y: 0, ease: Power2.easeOut, duration: firstDur / 2, opacity: 1 }
  //         );
  //       },
  //       onLeaveBack: (self) => {
  //         gsap.fromTo(
  //           ".about-title-1",
  //           {
  //             opacity: 1,
  //           },
  //           {
  //             opacity: 0,
  //             ease: Power2.easeOut,
  //             // delay: firstDur / 8,
  //             // duration: 2,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-title-2",
  //           {
  //             opacity: 1,
  //           },
  //           {
  //             opacity: 0,
  //             // delay: firstDur / 3,
  //             ease: Power2.easeOut,

  //             // duration: 2,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-title-3",
  //           {
  //             opacity: 1,
  //           },
  //           {
  //             opacity: 0,
  //             ease: Power2.easeOut,
  //             // delay: firstDur / 2,
  //             // duration: 2,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model",
  //           { y: 0, opacity: 1 },
  //           {
  //             y: firsty / 2,
  //             opacity: 0,
  //             ease: Power2.easeOut,
  //             // duration: firstDur / 2,
  //           }
  //         );
  //       },
  //     },
  //   });

  //   let tl2 = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: ".images-row-first", // Trigger is on about-model-col
  //       onLeave: (self) => {
  //         gsap.fromTo(
  //           ".about-model-first",
  //           {
  //             y: 0,
  //           },
  //           { y: -1 * firsty, ease: Power1.easeIn, duration: firstDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-second",
  //           {
  //             y: 0,
  //           },
  //           { y: -1 * secondY, ease: Power1.easeIn, duration: secondDur }
  //         );
  //         gsap.fromTo(
  //           ".about-model-third",
  //           {
  //             y: 0,
  //           },
  //           { y: -1 * thirstY, ease: Power1.easeIn, duration: thirstDur }
  //         );
  //       },
  //       onEnterBack: (self) => {
  //         gsap.fromTo(
  //           ".about-model-first",
  //           { y: -1 * firsty, ease: Power1.easeIn, duration: firstDur },
  //           {
  //             y: 0,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-second",
  //           { y: -1 * secondY, ease: Power1.easeIn, duration: secondDur },
  //           {
  //             y: 0,
  //           }
  //         );
  //         gsap.fromTo(
  //           ".about-model-third",
  //           { y: -1 * thirstY, ease: Power1.easeIn, duration: thirstDur },
  //           {
  //             y: 0,
  //           }
  //         );
  //       },
  //     },
  //   });

  // // add to document.getElemetById("test-col-1")
  // // When we leave about, do this
  // var rect = image.getBoundingClientRect(); // get pos of image right now
  // // var classes = document.getElementById("test-col-1").classList; // get all classes in container element
  // document.getElementById("test-col-1").appendChild(image); // append image under new parent
  // TweenMax.to(image, 1, {});
  // TweenMax.set(image, { x: 0, y: 0 }); // ??
  // var newImage = image.getBoundingClientRect();
  // TweenMax.from(image, 1, {
  //   x: rect.left - newImage.left,
  //   y: rect.top - newImage.top,
  //   ease: Power3.easeOut,
  // if (classes.contains("")) {
  //   document
  //     .getElementById("first-image")
  //     .classList.remove("about-model-first");
  // } else {
  //   document
  //     .getElementById("first-image")
  //     .classList.add("about-model-first");
  // }
  // console.log("called");
  // document
  //   .getElementById("first-image")
  //   .classList.add("about-model-first");
  // gsap.to(".about-model-col", {
  //   opacity: 1,
  //   y: 800,
  //   x: 30,
  // });
  // gsap.fromTo(
  //   "#first-image",
  //   { opacity: 0 },
  //   {
  //     opacity: 0,
  //     duration: 3,
  //   }
  // );

  //   gsap.to(".about-model-col", { opacity: 1, y: 0, x: 0 });
  //   document
  //     .getElementById("first-image")
  //     .classList.remove("about-model-first");
  // },

  // ScrollTrigger.create({
  //   trigger: ".about-model-col",
  //   onLeave: (self) => {
  //     console.log("called");
  //     gsap.fromTo(
  //       ".about-model-col",
  //       { opacity: 1, y: 0 },
  //       { opacity: 1, y: 800 }
  //     );
  //   },
  //   onLeaveBack: (self) => {
  //     gsap.to(".about-model-col", gsap.defaults);
  //   },
  // });
  // }, []);
  return (
    <div className="about-wrapper" id={props.id}>
      <img src={logo} alt="" className="logo" />
      <div className="about-title-wrapper">
        <h1
          className="about-title-1"
          style={{ letterSpacing: "0.423vw", marginBottom: "-0.977vw" }}
        >
          high end
        </h1>
        <h1 className="about-title-2" style={{ letterSpacing: "0.635vw" }}>
          traditional
        </h1>
        <h1 className="about-title-3" style={{ letterSpacing: "0.846vw" }}>
          luxury designs
        </h1>

        <Container
          className="about-content-wrapper"
          style={{ paddingBottom: "6.51vw" }}
        >
          <Row className="about-model-wrapper">
            <Col lg={4} className="about-model-col" id="about-model-col-id">
              <picture className="test-image">
                <source srcSet={model} type="image/webp" />
                <img
                  src={modelJPG}
                  alt=""
                  className="about-model"
                  id="first-image"
                />
              </picture>
            </Col>
            <Col className="about-content-text-wrapper">
              <p>
                Malaki Caftans & Jewels verhuurt traditionele caftans en
                sieraden gericht op bruiden en gasten. Ook verhuren wij complete
                bridal caftans inclusief bridal sieraden. Bij ons kunt u ook
                feestelijke jelabat huren voor een kleine gelegenheid zoals een
                geboortefeest, verlovingsfeest of ghorja. Wij hebben een ruime
                keus in onze verhuur collectie.
              </p>
              <p>
                Onze verhuurcaftans & jelabat zijn uniek in hun design. Wij
                hebben van elk design maar één item en dat maakt onze
                verhuurcollectie speciaal. Onze stijl is traditioneel en bij ons
                staat kwaliteit & service op nummer 1.
              </p>
              <p>
                Onze missie is om de vrouwen zelfvertrouwen te doen uitstralen
                als zij schitteren in onze creaties. Wij halen onze inspiratie
                voornamelijk uit het Marokkaans koninklijk huis.
              </p>
              <p>
                Naast het verhuren van caftans, kunt u bij ons ook terecht voor
                o.a sjaals, feestschoenen, tassen, sieraden en andere
                accessoires. Wij verhuren ook een complete hennaset en
                bijbehorende tyafers. Voor informatie hierover kunt u langskomen
                in onze winkel.
              </p>
              <p>
                Let op! Niet de gehele collectie staat online. Malaki Caftans &
                Jewels beschikt over meer caftans dan op de site en sociale
                media wordt weergegeven.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default About;
