import "./Home.scss";
import background from "../../assets/images/Malaki_Home.webp";
import backgroundJPG from "../../assets/images/Malaki_Home.jpg";
import logo from "../../assets/images/logo.png";
import React from "react";

class Home extends React.Component {
  render() {
    return (
      <div className="home-wrapper" id={this.props.id}>
        <div className="home-image-wrapper" id="home-wrapper">
          <picture>
            <source srcSet={background} type="image/webp" />
            <img
              src={backgroundJPG}
              className="home-image"
              alt="Female holding a bag"
            />
          </picture>
        </div>

        <img
          src={logo}
          alt="Malaki caftans and jewels logo "
          className="logo"
        />

        <div className="home-info">
          <div className="title-wrapper">
            <span className="home-title-Caftans">Caftans</span>
            <span className="home-title-jewels">Jewels</span>
            <span className="home-title-bridal">Bridal caftans</span>
            <span className="home-title-and">&</span>
            <span className="home-title-products">
              Shoes | Scarves | Bags | Tyafer | Henna decoration
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
