import React from "react";
import facebook from "../../assets/images/social/fb.png";
import snapchat from "../../assets/images/social/sn.png";
import instagram from "../../assets/images/social/ig.png";
import "./CustomNav.scss";
class CustomNav extends React.Component {
  render() {
    return (
      <div className="nav-wrapper" id="navbar">
        <ul className="nav-list">
          <li>
            <a href="#0">HOME</a>
          </li>

          <li>
            <a href="#1">OVER ONS</a>
          </li>

          <li>
            <a href="#4">CONTACT</a>
          </li>
        </ul>
        <div className="socials-wrapper">
          <a href="https://www.snapchat.com/add/malakicj">
            <img src={snapchat} alt="snapchat" />
          </a>
          <a href="https://instagram.com/malaki_caftans_jewels?igshid=1mfjorckaemdx">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://www.facebook.com/malaki.caftansjewels.5">
            <img src={facebook} alt="facebook" />
          </a>
        </div>
      </div>
    );
  }
}
export default CustomNav;
