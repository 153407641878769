import React from "react";
import { Col, Row } from "react-bootstrap";
import Store from "../../assets/images/Malaki_store.webp";
import StoreJPG from "../../assets/images/Malaki_Store.jpg";
import "./Contact.scss";
class Contact extends React.Component {
  render() {
    return (
      <div id={this.props.id} className="contact">
        <div id="trigger-third"></div>
        <Row className="contact-title-row">
          <Col lg={2}></Col>
          <Col lg={7}>
            <h1 className="contact-title">All women deserve a royal look</h1>
          </Col>
          <Col lg={1}></Col>
        </Row>
        <Row id={this.props.id}>
          <Col lg={4} className="contact-store-image-wrapper">
            <picture>
              <source srcSet={Store} type="image/webp" />
              <img src={StoreJPG} alt="" className="contact-store-image" />
            </picture>
          </Col>
          <Col lg={4} className="contact-openingstijden-col">
            <h2>openingstijden</h2>
            <div className="contact-openingstijden-wrapper">
              <ul>
                <li>Maandag</li>
                <li>Dinsdag</li>
                <li>Woensdag</li>
                <li>Donderdag</li>
                <li>Vrijdag</li>
                <li>Zaterdag</li>
                <li>Zondag</li>
              </ul>
              <ul>
                <li>12:00 – 18:00</li>
                <li>Gesloten</li>
                <li>10:00 – 18:00</li>
                <li>10:00 – 19:00</li>
                <li>10:00 – 19:00</li>
                <li>10:00 – 18:00</li>
                <li>12:00 – 18:00</li>
              </ul>
            </div>
            <p className="text-center">Wij blijven momenteel nog werken op afspraak.
Een afspraak maken kan telefonisch of per mail.</p>
          </Col>
          <Col lg={3} className="contact-other-col">
            <p>telefoon</p>
            <p>0031 (0)20 84 56 542</p>
            <p>email</p>
            <p>info@malaki.nl</p>
            <p>adres</p>
            <p>Lambertus Zijlplein 19 1067 JR Amsterdam</p>
            <div className="contact-other-col-info">
              <p>*Gratis parkeren in de omgeving</p>
              <p>*Tram 13 geuzenveld eindhalte </p>
            </div>
          </Col>
        </Row>
        <a
          href="http://toms-crew.nl"
          rel="noreferrer"
          target="_blank"
          className="toms-crew-link"
        >
          <p className="contact-credits">© 2021 - TOM'S-CREW.nl</p>
        </a>
      </div>
    );
  }
}
export default Contact;
