import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import model from "../../assets/images/Malaki_Portfolio_about.webp";
import model2 from "../../assets/images/Malaki_Portfolio3.webp";
import model3 from "../../assets/images/Malaki_Portfolio4.webp";
import modelJPG from "../../assets/images/Malaki_Portfolio_about.jpg";
import model2JPG from "../../assets/images/Malaki_Portfolio3.jpg";
import model3JPG from "../../assets/images/Malaki_Portfolio4.jpg";
import "./Images.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Images(props) {
  // useEffect(() => {

  //   gsap.registerPlugin(ScrollTrigger); // Register scroll
  //   let tl = gsap.timeline({
  //     // Create timeline
  //     scrollTrigger: {
  //       trigger: ".about-model-col", // Trigger is on about-model-col
  //       onLeave: (self) => {
  //         gsap.to("#first-image", {
  //           opacity: 0,
  //           ease: Power3.easOut,
  //           duration: 0.2,
  //         });
  //       },
  //       onEnterBack: (self) => {
  //         gsap.to("#first-image", {
  //           opacity: 1,
  //           ease: Power3.easOut,
  //           duration: 1,
  //         });
  //       },
  //     },
  //   });

  return (
    <Row id={props.id} className="images-row images-row-first">
      <Col
        className="images-col-1 images-1"
        lg={4}
        style={{ left: "13.021vw", top: "6.51vw" }}
        id="test-col-1"
      >
        <picture>
          <source srcSet={model} type="image/webp" />
          <img
            src={modelJPG}
            alt=""
            style={{ width: "100%", maxWidth: "100%" }}
            className="about-model-first"
          />
        </picture>
      </Col>
      <Col lg={5} className="images-col-2">
        <picture>
          <source srcSet={model2} type="image/webp" />
          <img src={model2JPG} alt="" className="about-model-second" />
        </picture>
      </Col>
      <Col
        lg={3}
        style={{ left: "-6.51vw", top: "9.766vw" }}
        className="images-col-3"
      >
        <picture>
          <source srcSet={model3} type="image/webp" />
          <img src={model3JPG} alt="" className="about-model-third" />
        </picture>
      </Col>
    </Row>
  );
}

export default Images;
